import React, { useState, useEffect } from 'react';
import "./App.css";
import PubNub from 'pubnub';
import helper from './IH'
let prevR = '';
window.scroll_ = 120;
const pessoas = [
{ id:1 ,name: "Maomé", image: 'https://pm1.aminoapps.com/6513/36d19e454e14453f1269c83897a8238c631a414a_128.jpg'},
{ id:2 ,name: "Isaac Newton", image: 'https://pm1.aminoapps.com/6352/1ed23bbf07b0e05b5acae2e173136f28ecfa2eeb_128.jpg'},
{ id:3 ,name: "Jesus de Nazaré", image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRyy_UKs_V5gaKYdjlHhAZc2Gcig441m7Mwwg&usqp=CAU'},
{ id:4 ,name: "Sidarta Gautama", image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmdB9lecYipOSwMOXR26tr6a63wNLZagpbqC4V3JUS3bOS0V0xjFUd9OImQfCyrPGi3Rg&usqp=CAU'},
{ id:5 ,name: "Confúcio", image: 'https://pbs.twimg.com/profile_images/641967270860713985/LE_fsqPG_400x400.png'},
{ id:6 ,name: "Paulo de Tarso", image: 'https://i.pinimg.com/originals/0a/5c/1d/0a5c1d3f6522c31795df936596b18ec5.jpg'},
{ id:7 ,name: "Cai Lun", image: 'https://static.wixstatic.com/media/206beb_4d0846a9806f4302b4c80500167bd8e4~mv2.jpg/v1/fit/w_320%2Ch_499%2Cal_c%2Cq_80,enc_auto/file.jpg'},
{ id:8 ,name: "Johannes Gutenberg", image: 'https://i.pinimg.com/474x/b0/ab/79/b0ab792943e0ded2683928e1bcc98a83.jpg'},
{ id:9 ,name: "Cristovão Colombo", image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2lSl5UL6YNQA3hka_43vnnPoolYjxMZ0FlA&usqp=CAU'},
{ id:10 ,name: "Albert Einstein", image: 'https://pbs.twimg.com/profile_images/676461962046398464/k-nnxJlK_400x400.jpg'},
{ id:11 ,name: "Louis Pasteur", image: 'https://www.infoescola.com/wp-content/uploads/2010/04/louis-pasteur.jpg'},
{ id:12 ,name: "Galileu Galilei", image: 'https://cdn.pensador.com/img/authors/ga/li/galileu-galilei-l.jpg'},
{ id:13 ,name: "Aristóteles de Estagira", image: 'https://opiniaocentral.files.wordpress.com/2013/08/aristoteles.jpg'},
{ id:14 ,name: "Euclides de Alexandria", image: 'https://clube.spm.pt/files/clube/outros/euclides.jpg'},
{ id:15 ,name: "Moisés", image: 'https://qph.cf2.quoracdn.net/main-qimg-9bf4f1d6166272e2e3611a04bab3e677-lq'},
{ id:16 ,name: "Charles Darwin", image: 'https://www.infoescola.com/wp-content/uploads/2017/08/charles-darwin.jpg'},
{ id:17 ,name: "Qin Shihuang", image: 'https://miro.medium.com/v2/0*KbQ2ebZtTFBgw2Ef'},
{ id:19 ,name: "Nicolau Copérnico", image: 'https://www.algosobre.com.br/images/stories/assuntos/biografias/Nicolau%20Copernico.jpg'},
{ id:20 ,name: "Antoine Lavoisier", image: 'https://s.ebiografia.com/assets/img/authors/an/to/antoine-lavoisier-m.jpg'},
{ id:21 ,name: "Constantino", image: ''},
{ id:22 ,name: "James Watt", image: ''},
{ id:23 ,name: "Michael Faraday", image: ''},
{ id:24 ,name: "James Clerk Maxwell", image: ''},
{ id:25 ,name: "Martinho Lutero", image: ''},
{ id:26 ,name: "George Washington", image: ''},
{ id:27 ,name: "Karl Marx", image: ''},
{ id:28 ,name: "Irmãos Wright", image: ''},
{ id:29 ,name: "Gêngis Cã", image: ''},
{ id:30 ,name: "Adam Smith", image: ''},
{ id:31 ,name: "William Shakespeare", image: ''},
{ id:32 ,name: "John Dalton", image: ''},
{ id:33 ,name: "Alexandre", image: ''},
{ id:34 ,name: "Napoleão Bonaparte", image: ''},
{ id:35 ,name: "Nikola Tesla", image: ''},
{ id:36 ,name: "Anton van Leeuwenhoek", image: ''},
{ id:37 ,name: "William Thomas Green Morton", image: ''},
{ id:38 ,name: "Guglielmo Marconi", image: ''},
{ id:39 ,name: "Adolf Hitler", image: ''},
{ id:40 ,name: "Platão de Atenas", image: ''},
{ id:41 ,name: "Oliver Cromwell", image: ''},
{ id:42 ,name: "Alexander Graham Bell", image: ''},
{ id:43 ,name: "Alexander Fleming", image: ''},
{ id:44 ,name: "John Locke", image: ''},
{ id:45 ,name: "Ludwig van Beethoven", image: ''},
{ id:46 ,name: "Werner Heisenberg", image: ''},
{ id:47 ,name: "Louis Daguerre", image: ''},
{ id:48 ,name: "Simon Bolívar", image: ''},
{ id:49 ,name: "René Descartes", image: ''},
{ id:50 ,name: "Michelangelo", image: ''},
{ id:51 ,name: "Urbano II", image: ''},
{ id:52 ,name: "Omar ibne Alcatabe", image: ''},
{ id:53 ,name: "Açoca", image: ''},
{ id:54 ,name: "Agostinho de Hipona", image: ''},
{ id:55 ,name: "William Harvey", image: ''},
{ id:56 ,name: "Ernest Rutherford", image: ''},
{ id:57 ,name: "João Calvino", image: ''},
{ id:58 ,name: "Gregor Mendel", image: ''},
{ id:59 ,name: "Max Planck", image: ''},
{ id:60 ,name: "Joseph Lister", image: ''},
{ id:61 ,name: "Nikolaus August Otto", image: ''},
{ id:62 ,name: "Francisco Pizarro", image: ''},
{ id:63 ,name: "Hernán Cortés", image: ''},
{ id:64 ,name: "Thomas Jefferson", image: ''},
{ id:65 ,name: "Isabel I de Castela e Fernando II de Aragão", image: ''},
{ id:66 ,name: "Joseph Stalin", image: ''},
{ id:67 ,name: "Júlio César", image: ''},
{ id:68 ,name: "Guilherme I", image: ''},
{ id:69 ,name: "Sigmund Freud", image: ''},
{ id:70 ,name: "Edward Jenner", image: ''},
{ id:71 ,name: "Wilhelm Röntgen", image: ''},
{ id:72 ,name: "Johann Sebastian Bach", image: ''},
{ id:73 ,name: "Lao Tsé", image: ''},
{ id:74 ,name: "Voltaire (François Marie Arouet)", image: ''},
{ id:75 ,name: "Johannes Kepler", image: ''},
{ id:76 ,name: "Enrico Fermi", image: ''},
{ id:77 ,name: "Leonhard Euler", image: ''},
{ id:78 ,name: "Jean-Jacques Rousseau", image: ''},
{ id:79 ,name: "Nicolau Maquiavel", image: ''},
{ id:80 ,name: "Thomas Malthus", image: ''},
{ id:81 ,name: "John F. Kennedy", image: ''},
{ id:82 ,name: "Gregory Pincus", image: ''},
{ id:83 ,name: "Manes", image: ''},
{ id:84 ,name: "Lenin (Vladimir Illich Ulyanov)", image: ''},
{ id:85 ,name: "Sui Went-Ti", image: ''},
{ id:86 ,name: "Vasco da Gama", image: ''},
{ id:87 ,name: "Ciro II", image: ''},
{ id:88 ,name: "Pedro I", image: ''},
{ id:89 ,name: "Mao Tsé-tung", image: ''},
{ id:90 ,name: "Francis Bacon", image: ''},
{ id:91 ,name: "Henry Ford", image: ''},
{ id:92 ,name: "Mêncio (Ji Mèngkē)", image: ''},
{ id:93 ,name: "Zoroastro", image: ''},
{ id:94 ,name: "Isabel I", image: ''},
{ id:95 ,name: "Mikhail Gorbachev", image: ''},
{ id:96 ,name: "Menés", image: ''},
{ id:97 ,name: "Carlos Magno", image: ''},
{ id:98 ,name: "Homero", image: ''},
{ id:99 ,name: "Justiniano I", image: ''},
{ id:100 ,name: "Mahavira", image: ''}];


const ChatComponent = () => {
  const [mensagens, setMensagens] = useState([]);
  const [users, setUsers] = useState([]);
  const [mensagemAtual, setMensagemAtual] = useState('');
  const [username, setUsername] = useState(localStorage.getItem('username') || 'usuario');
  const [pessoa, setPessoa] = useState(null);
  const [loading, setLoading] = useState(false);
  const uuid = localStorage.getItem('uuid')? localStorage.getItem('uuid') :  username + '#[' + Date.now() + ']#';

  const pubnub = new PubNub({
    publishKey: 'pub-c-b5fa0f3d-07b0-4ffd-9a83-5db51410ff0a',
      subscribeKey: 'sub-c-dd431a54-2822-4015-bbaf-6a51d9d99b38',
    uuid: uuid, // Pode ser qualquer valor único para identificar o usuário
  });


  window.addEventListener('beforeunload', (e) => {
    pubnub.publish({
      channel: 'chat_channel',
      message: '#[s][name='+username+']s# saiu' });
  });

  helper.on('setLoading', _ => {
    setLoading(_)
  },0)

  helper.on('msgai', _ => {
    if(_ !== prevR){
      
      //console.log(prevR, _)
      helper.emit('addmsg', _)
      prevR = _;
      pubnub.publish({ channel: 'chat_channel', message: '#[ai] '+_ }, (st, rs)=>{
        helper.emit('setLoading', false)
      })
      var objDiv = document.getElementById("messages");
      objDiv.scrollTop = objDiv.scrollHeight;
    }
  }, 0)

  useEffect(() => {
    //setTimeout(function(){

      var objDiv = document.getElementById("messages");
            objDiv.scrollTop = objDiv.scrollHeight;
            
    //},500)
  }, [mensagens]) 


  useEffect(() => {
    //setTimeout(function(){

    localStorage.setItem('uuid',uuid );
            
    //},500)
  }, [username]) 

  useEffect(() => {
    // Assinar um canal para receber mensagens
    pubnub.addListener({
      message: (message) => {
        setMensagens((mensagens) => [...mensagens, message.message]);
        //message.message.indexOf('#[ai]') !== -1 ? setLoading(false): console.log(loading)
        var objDiv = document.getElementById("messages");
      objDiv.scrollTop = objDiv.scrollHeight;
      
      },
    });
    pubnub.subscribe({ channels: ['chat_channel'] });
    pubnub.publish({
      channel: 'chat_channel',
      message: '#[l][name='+username+']l# entrou' });
    
    return () => {
      pubnub.unsubscribeAll();
    };
  }, []);
  
  const enviarMensagem = (condition) => {
    //if(condition !== false){
      //}
      //debounce(_ => {
        if (mensagemAtual.trim() !== '') {
          helper.emit('setLoading', true)
          pubnub.publish({ channel: 'chat_channel', message: '#[u][name='+username+']u#' + mensagemAtual },(st, rs)=>{
            //helper.emit('setLoading', false)
            debounce(helper.emit('sendmsg', mensagemAtual, mensagemAtual),100)
          });
        setMensagemAtual('');
      }

     // }, 100)
   // setTimeout(function(){
      // var objDiv = document.getElementById("messages");
      // objDiv.scrollTop = objDiv.scrollHeight;

    //},500)
  };

  const handleChange = (event) => {
    setMensagemAtual(event.target.value);
  };

  const handleChangeUser = (event) => {
      localStorage.setItem('username', event.target.value)
      setUsername(event.target.value);
    };

  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  }
  const handleKeyDown = function(e) {
    if (e.key === 'Enter') {
      enviarMensagem();
    }
  }
  const selecionaPessoa = function(pessoa){
    helper.emit('selectPessoa', pessoa)
    setPessoa(pessoa.name);
  }
  // pubnub.hereNow({
  //     channels: ['chat_channel'],
  //   includeUUIDs: true,
  //   includeState: true,
  // }, (status, response) => {
  //   // handle status, response
  //   setUsers(response.channels.chat_channel.occupants)
  //   }); 
  return (
    <div className='container-fluid'>
      <div className='sidebar'>
        <span className='logo'>
          <i className="fa-solid fa-cloud"></i> talk-to.cloud
        </span>
          <div className='pessoas'>
          {pessoas.map((p, index) => (
            <div key={index} onClick={_ => selecionaPessoa(p)} className={p.name === pessoa? 'pessoa active':'pessoa' }>
              <img className="pessoa-imagem" src={p.image?p.image:'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'}></img>
              {p.name}
            </div>
          )
          
          )}
          </div>
      </div>
      <div className='container'>
      <div className='row justify-content-end'>
        <div className="col-10 ">
          <ul className="list-group messages" id="messages">
          {mensagens.map((mensagem, index) => (
            <div key={index} >
                {
                  (_ => {
                    if(mensagem.indexOf("#id[") !== -1){
                      let pi = parseInt(mensagem.substring(mensagem.indexOf("#id[") + 4, mensagem.lastIndexOf("]id#")));
                      let po = pessoas.filter((o, i)=>{
                          return pi == o.id
                      });
                      return (
                        <li className="name-person animate__animated animate__fadeInUp">
                          <img src={po[0].image?po[0].image:'https://cdn-icons-png.flaticon.com/512/6596/6596121.png'} />
                          <span>
                            {po[0].name}
                          </span>
                        </li>
                        )
                    }
                  })()
                }

              <li 
              className={` list-group-item ${mensagem.indexOf('#[u]') !== -1 ?'animate__animated animate__fadeInLeft':''} ${mensagem.indexOf('#[l]') !== -1 ?'status animate__zoomIn animate__animated':''} ${mensagem.indexOf('#[s]') !== -1 ?'status logout animate__zoomIn animate__animated':''} ${mensagem.indexOf('#[ai]') !== -1 ?'active animate__animated animate__fadeInRight':''} ${mensagem.indexOf('#[ai]') !== -1  && mensagem.indexOf('Error:') !== -1 ?'error':''}`}
              dangerouslySetInnerHTML={ {__html: `${mensagem.replace(/#id\[\d+]id# /, '').replace('#[ai] ', '').replace('#[u][name=','<b>').replace(']u#', '</b> - ').replace('#[l][name=','').replace(']l#', '').replace('#[s][name=','').replace(']s#', '')}`} }>
                
              </li>
            </div>
          )
          
          )}
          <i className={loading?`fa-solid fa-spinner fa-spin loading-icon fa-spin-pulse`:``}></i>
        </ul>
      <div className='input-group mb-3'>
        <input
          className="form-control change-user"
          type="text"
          value={username}
          onChange={handleChangeUser}
          placeholder="Digite seu usuário"
          disabled={loading}
        />
        <input
          className="form-control mensagem-atual"
          type="text"
          value={mensagemAtual}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder="Digite sua mensagem..."
          disabled={loading}
        />
        <button
        disabled={loading}
        className="btn btn-success"
        onClick={enviarMensagem}><i className="fa-solid fa-paper-plane"></i></button>
      </div>
      </div>
      </div>
    </div>
  </div>
  );
};

export default ChatComponent;
