import {useEffect, useRef, useState} from "react"
import { sendMsgToOpenAI } from "./openai";
import helper from './IH'
let prevP = '';
let prevP_ = [];
let prevMsgId = 0;
let pessoa = {}
helper.on('selectPessoa', (_) => {
  console.log('selectPessoa')
  pessoa = _;
}, 0);

function App() {
  helper.on('addmsg', (_) => {
    prevP_.push('[resposta_ai_' + Date.now() + '] - ' + _);
  }, 0);

  helper.on('sendmsg', (_, p) => {
    console.log('sendmsg')
    if(p !== prevP){
      helper.emit('setLoading', true)
      prevP = p;
      const _handleSend = async (a) => {
        let text = a;
        setMessages([
          ...messages,
          {text, isBot: false}
        ])
        try{
          if(prevP_[prevP_.length - 2] !== "" && prevP_.length > 0){
            if(pessoa.name == undefined){
              text = "As mensagens anteriores dessa conversa estão nesse array: " + JSON.stringify(prevP_) + " não precisa estar vinculado ao array a resposta, mas caso faça sentido utilize-o como contexto, responda a mensagem atual: [" + text + "]";
            } else {
              text = "Responda o seguinte prompt como se fosse " + pessoa.name + ", caso não consiga responder como essa pessoa, passe as informações solicitadas com base no seu conhecimento sobre tal. segue o prompt: " + text;
            }
            
            //console.log(text)
          }
          prevP_.push('[pergunta_user_' + Date.now() + '] ' + p );
          let res = await sendMsgToOpenAI(text);
          helper.emit('setLoading', false)
          if(pessoa.id !== undefined){
            res = `#id[${pessoa.id}]id# ${res}`;
          }
          debounce(helper.emit('msgai', res), 1000);
          
          //console.log(pessoa.id)
          
          setMessages([...messages, {text, isBot:false }, {text: res, isBot: true}])
          // var objDiv = document.getElementById("messages");
          // objDiv.scrollTop = objDiv.scrollHeight;
        } catch(e){
          console.log(e)
          helper.emit('setLoading', false)
          helper.emit('msgai', e)
        }
      }
      _handleSend(_);
    }

  }, 0);


  const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
  }

  const msgEnd = useRef(null);
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([{
    text: "Hi, I am ChatGPT, a state-of-the-art language model",
    isBot: true,
  }]);


  const handleSend = async () => {
    const text = input;
    setInput("");
    setMessages([
      ...messages,
      {text, isBot: false}
    ])
    console.log('teste1')
    const res = await sendMsgToOpenAI(text);
    console.log('teste2')
    helper.emit('msgai', res)
    setMessages([...messages, {text, isBot:false }, {text: res, isBot: true}])
  }

  const handleEnter = async (e) =>  {
    if (e.key === 'Enter')  await handleSend();
  }


  return (
    <div >
      <div >
        <div>
          <div ref={msgEnd}/>
        </div>
      </div>
    </div>
  );
}

export default App;
